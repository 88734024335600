import React, { useState } from 'react';
import { group1, clevercruit } from '../assets/imgs';
import { Outlet } from 'react-router-dom';
import { getLocal } from '../utils/localStorage';

const BackgroundLayout = () => {
  // const fname=getLocal('candFName');
  // const lname=getLocal('candLName');

  return (
    <div className="h-max w-[100vw] items-center">
      <div className=" flex flex-col  text-white flex justify-center items-center">
        <div className=" w-[96vw] mt-[3vh]  rounded-2xl border-[1.5px] backdrop-blur-md border-blue-700 p-4">
          <div className="flex justify-around gap-4 mb-[1rem]">
            {/* <span className="w-[4.7rem] h-[4.5rem] rounded-lg flex items-center justify-center border-[15px] border-black border-opacity-25 ">
              <img src={group1} alt="group1" className="h-5 w-5 " />
            </span> */}
            <span className="w-full h-[4.5rem]  rounded-xl flex items-center justify-between border-[15px] border-black border-opacity-25 ">
              <img src={clevercruit} alt= "clevercruit logo" className='ml-2'/>
             {/* {fname && (<p className="ml-2 font-extralight mr-2">Hi, {fname} {lname}!</p>)} */}
            </span>
          </div>
     
            <Outlet/>
       
        </div>
      </div>
    </div>
  );
};

export default BackgroundLayout;
