import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getLocal } from '../utils/localStorage';
import { formatTime } from '../utils/formatTime';
import { clevercruit } from '../assets/imgs';

export const MessageInput = ({
  openEditor,
  faceSocket,
  setQuestCounter,
  setMessages,
  sendImg,
  setMessage,
  message,
  setIsListening,
  setTimerRunning,
  socket,
  setSocket,
  timer,
  setTimer,
  timerRunning,
  setIsSpeaking,
  isSpeaking,
  setEndInterviewCounter,
  inputHeight,
  setInputHeight,
  loader,
  setLoader,
  questcounter,
  warningCount,
  exit,
  setNext,
  next,
}) => {
  // const uid = useSelector((state) => state?.login?.user?.uid);
  // console.log('first', message);
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const convertToSeconds = (time) => {
    const [minutes, seconds] = time?.split(':')?.map(Number);
    return minutes * 60 + seconds;
  };

  const formatTimeFromSeconds = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  };

  const calculateRemainingTime = (currentTime) => {
    const startTime = '01:30';
    const startInSeconds = convertToSeconds(startTime);
    const currentInSeconds = convertToSeconds(currentTime);
    const remainingTimeInSeconds = startInSeconds - currentInSeconds;
    return formatTimeFromSeconds(remainingTimeInSeconds);
  };

  const currentTime = formatTime(timer);
  const remainingTime = calculateRemainingTime(currentTime);

  // useEffect(() => {
  //   if (!message) {
  //     console.log("this is also working");
  //     setInputHeight("3.5rem");
  //   }
  // }, [message]);

  // useEffect(() => {
  // }, [timerRunning]);

  const sendMessage = (message) => {
    if (message.trim().length === 0) {
      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN) {
      const send = JSON.stringify({
        text: message,
        time: remainingTime,
        // suspicious_count: warningCount,
      });
      socket.send(send);
      console.log("message sent", send);
    } else {
      console.error("WebSocket connection not established.");
    }
  };

  // const handleMessageChange = (e) => {
  //   setIsListening(false);

  //   if (!e.target.value.trim()) {
  //     setInputHeight("3.5rem");
  //   }
  //   if (e.key === "Enter" && !e.shiftKey) {
  //     e.preventDefault();
  //     handleMessageSubmit(e);
  //   } else {
  //     setMessage(e.target.value);
  //   }
  // };

  // useEffect(() => {
  //   adjustInputHeight();
  // }, [message]);

  // const adjustInputHeight = () => {
  //   const input = document.getElementById("messageInput");
  //   setInputHeight(`${input.scrollHeight}px`);
  //   if (input.scrollHeight <= 112) {
  //     setInputHeight(`${input.scrollHeight}px`);
  //     input.scrollTop = input.scrollHeight;
  //   } else {
  //     setInputHeight("7rem");
  //     input.scrollTop = input.scrollHeight;
  //   }
  // };

  const handleKeyUp = () => {
    setInputHeight('3.5rem');
  };

  // const handleMessageSubmit = (e) => {
  //   // e.preventDefault();
  //   if (!isSpeaking && socket) {
  //     setIsListening(false);
  //     sendMessage(message);
  //     let newMessage = {}
  //     if(next === "NO"){
  //        newMessage = {
  //         text: message.trim(),
  //         isUser: true,
  //         isNext : true,
  //       };
  //     }else{
  //       newMessage = {
  //         text: message.trim(),
  //         isUser: true,
  //       };
  //     }

  //     if (newMessage.text !== "") {
  //       setMessages((prevMessages) => [...prevMessages, newMessage]);
  //       setMessage("");
  //       handleKeyUp();
  //       setTimerRunning(false);
  //       setLoader(true);
  //       setNext("")
  //     }
  //   }
  // };

  // const sendMessage = (message) => {
  //   if (message.trim().length === 0) {
  //     return;
  //   }

  //   if (socket && socket.readyState === WebSocket.OPEN) {
  //     const send = JSON.stringify({
  //       text: message,
  //       time: remainingTime,
  //     });
  //     socket.send(send);
  //     console.log('message sent', send);
  //   } else {
  //     console.error('WebSocket connection not established.');
  //   }
  // };

  const handleMessageSubmit = (e) => {
    if (!isSpeaking && socket) {
      setIsListening(false);
      sendMessage(message);

      let newMessage = {};
      if (next === 'NO') {
        newMessage = {
          text: message.trim(),
          isUser: true,
          isNext: true,
        };
      } else {
        newMessage = {
          text: message.trim(),
          isUser: true,
        };
      }

      if (newMessage.text !== '') {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];

          if (next === 'NO') {
            for (let i = updatedMessages.length - 1; i >= 0; i--) {
              if (
                updatedMessages[i].text ===
                  'Do you want to proceed to the next question?' ||
                updatedMessages[i].text === 'do you want to continue?'
              ) {
                updatedMessages[i].text = '';
                break;
              }
            }
          }

          updatedMessages.push(newMessage);

          return updatedMessages;
        });

        setMessage('');
        handleKeyUp();
        setTimerRunning(false);
        sessionStorage.setItem("timer", 90);
        setLoader(true);
        setNext('');
      }
    }
  };

  useEffect(() => {
    handleMessageSubmit();
  }, [message && !openEditor, setMessage]);

  return (
    <div className="relative">
      {/* <form onSubmit={handleMessageSubmit}> */}
      {/* {loader && questcounter !== 15 && exit !== 'NO' && (
        <div className=" dotloader ms-80 mb-10"></div>
      )} */}
      {/* <textarea
        id="messageInput"
        className={`outline-0 fill-available p-4 pr-12 ${
          socket ? null : "pointer-events-none"
        }`}
        style={{
          minHeight: "3.5rem",
          maxHeight: "7rem",
          height: inputHeight,
        }}
        // placeholder="Type your message..."
        // value={message}
        // onChange={handleMessageChange}
        // onKeyDown={handleMessageChange}
        // onContextMenu={handleContextMenu}
        // onPaste={handlePaste}
        disabled
      /> */}

      {/* <button type="submit">
          <img src={sendImg} alt="send" className="absolute top-4 right-4" />
        </button> */}
      {/* </form> */}
    </div>
  );
};

export const TemplateBox = (props) => {
  return (
    <div className="p-[6px] relative h-[3.8rem] bg-dark-blue  text-center rounded-xl text-white w-[61%] text-text-color-code1">
      <div className="p-3 rounded-xl border-[2px] border-[#123364] h-[3rem] w-[100%] fill-available bg-gradient-to-r from-[#05162D] via-[#09162D] to-[#0F2845]  text-center fill-available ">
        <b>{props.boldText}</b>
        {props.text}
      </div>
    </div>
  );
};

export const TemplateBox1 = () => {
  return (
    <div className="p-[6px] relative h-[3.8rem] bg-dark-blue  text-center rounded-xl text-white w-[61%] text-text-color-code1">
      <div className="p-3 flex justify-center items-center rounded-xl border-[2px] border-[#123364] h-[3rem] w-[100%] fill-available bg-gradient-to-r from-[#05162D] via-[#09162D] to-[#0F2845]  text-center fill-available ">
        <img src={clevercruit} alt="clevercruit"/>
      </div>
    </div>
  );
};
