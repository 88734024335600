import React from "react";
import HighCharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DonutChart = ({ totalData }) => {
    console.log(totalData,"totalData")
  const data = [
    { name: "Don't manipulate", y: Number(totalData?.dontManPer), color: "#d57d00" },
    { name: "No face", y: Number(totalData?.noFacePer), color: "#975ced" },
    { name: "multi-face", y: Number(totalData?.multiface), color: "#9c9898" },
  ];

  const options = {
    chart: {
      type: "pie",
      height: "300px",
      strokeLinecap: "round",
    },
    plotOptions: {
      pie: {
        innerSize: "65%",
        size: "100%",
        depth: 45,

        dataLabels: {
          enabled: false,
        },
        borderWidth: 10,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    title: {
      text: `<div style="text-align: center; font-size: 30px; font-weight: bold;">${totalData?.total ? totalData?.total : `${totalData?.progres}%`}</div>`,
      verticalAlign: "middle",
      floating: true,
      y: 25,
    },
    tooltip: {
      shadow: false,
    },
    series: [
      {
        // name: "Share",
        data: data,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      <HighchartsReact highcharts={HighCharts} options={options} />
    </>
  );
};

export default DonutChart;
