import { toast } from "react-toastify";
import {
  arrowIcon,
  clockSvg,
  coverImg,
  shareIcon,
  shareIcon2,
  clevercruitlogo,
} from "../../assets/imgs";
import {
  Card1,
  Card2,
  Card3,
  Card4,
  DurationCard,
  PersonalCard,
  PersonalCard1,
  SegmentedCircleBar,
} from "../card";
import { ScoreCard } from "./card";
import { handleApiResponse } from "../../API/services";
import jsPDF from "jspdf";
import { useAsyncValue, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../loader";
import DonutChart from "../../utils/DonutChart";

export const NewAnalytics = () => {
  const navigate = useNavigate();
  const [isLeftOption, setIsLeftOption] = useState(true);
  const location = useLocation();
  const status = location?.state?.status || "Completed";
  const [overall_score, setOverall_score] = useState("");
  const [suspicious, setSuspicious] = useState("");
  const [comprehensiveAbility, setComprehensiveAbility] = useState("");
  const [fluency, setFluency] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [relevant_skills, setRelevantSkills] = useState("");
  const [description, setDescription] = useState("");
  const [stability, setStability] = useState("");
  const [teamColab, setTeamColab] = useState("");
  const [languageCommand, setLanguageCommand] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const uuid = searchParams.get("uid");
  const candId = searchParams.get("candId");
  const startDate = new Date(start);
  const endDate = new Date(end);
  const queryParams = new URLSearchParams(location.search);
  const [isLoader, setLoader] = useState(false);
  const [messages, setMessages] = useState([]);
  // console.log(evalData);
  console.log(start);
  console.log(end);
  console.log(uuid);
  const timeDifferenceInMillis = endDate - startDate;
  // console.log(messages)
  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(timeDifferenceInMillis / (1000 * 60));
  const seconds = Math.round((timeDifferenceInMillis % (1000 * 60)) / 1000);
  const candPhoto = queryParams.get("candPhoto").split("?")[0];
  const candName = queryParams.get("candName");
  const candCompany = queryParams.get("candCompany");
  const candJobRole = queryParams.get("candJobRole");
  const candExp = queryParams.get("candExp");
  const fullPhotoURL = `${candPhoto}`;
  const [marks, setMarks] = useState("0.275rem");
  const [candidateStability, setCandidateStability] = useState("");
  const [suspiciousCause, setSuspiciousCause] = useState([]);
  console.log(suspiciousCause);
  let noFaceDetectedCount = 0;
  let dontManipulateWindowCount = 0;
  let otherCount = 0;

  suspiciousCause.forEach((cause) => {
    if (cause === "No face detected") {
      noFaceDetectedCount++;
    } else if (cause === "Don't manipulate the window") {
      dontManipulateWindowCount++;
    } else {
      otherCount++;
    }
  });

  const totalCount = suspiciousCause.length;

  const noFaceDetectedPercentage = ((noFaceDetectedCount / 5) * 100).toFixed(2);
  const dontManipulateWindowPercentage = (
    (dontManipulateWindowCount / 5) *
    100
  ).toFixed(2);
  const otherPercentage = ((otherCount / 5) * 100).toFixed(2);

  useEffect(() => {
    setMarks(`${(0.275 + overall_score * 0.188).toFixed(2)}rem`);
  }, [overall_score]);

  console.log(fullPhotoURL);
  console.log(minutes);
  console.log(seconds);
  console.log(`Time difference: ${minutes} minutes and ${seconds} seconds`);

  const downloadPDF = (messages) => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2;
    const lineHeight = 10;
    let y = 10;
    let sender = "Candidate";

    console.log(messages);

    messages.forEach((message) => {
      y += 5;

      // Toggle sender between "AI" and "Interviewer" for each message
      sender = sender === "AI" ? "Candidate" : "AI";
      const text = `${sender}: ${message.msg}`;

      // Split text to fit within the page width
      const lines = doc.splitTextToSize(text, maxLineWidth);

      lines.forEach((line) => {
        // Check if adding this line will exceed the page height
        if (y + lineHeight > pageHeight - margin) {
          doc.addPage();
          y = margin; // Reset y position for the new page
        }
        doc.text(line, margin, y);
        y += lineHeight; // Move y position for the next line
      });

      y += 5; // Add some space between messages
    });

    doc.save("chat_transcript.pdf");
  };

  const handleDownloadTranscript = async (e) => {
    try {
      const response = await handleApiResponse(
        "/api/v1/interview/data/get_candidate_transcript/",
        {
          candidate_uid: uuid,
        },
        1
      );
      console.log("API Response:", response.data);
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      if (response.data.status_code === 200) {
        console.log(response.data.data);
        setMessages(response.data.data);
        downloadPDF(response.data.data);
        // dispatch(signupSuccess(response.data));
        // setLocal('adminToken', response.data.access);
        // setLocal('first_name', response.data.first_name);
        // setLocal('last_name', response.data.last_name);
        // setLocal('uid', response.data.uid);
        // setLocal('parentUid', response.data.parent);
        // setLocal(
        //   'notPermited',
        //   response?.data?.user_type === 3 ? true : false
        // );
        // if (!response?.data.is_email_verified) {
        //   setShowEmailVerification(true);
        // }
      }
    } catch (e) {}
  };
  console.log(relevant_skills);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleApiResponse(
          "/api/v1/get_performance_status/",
          {
            task_id: uuid,
            uid: candId,
          },
          1
        );
        console.log(response?.data);
        if (response?.code === "token_not_valid") {
          navigate("/");
          sessionStorage.clear();
          return;
        }
        if (response?.data.status_code === 200) {
          setLoader(false);
          console.log(response?.data.data.overall_score);
          setOverall_score(response?.data?.data?.result.overall_score);
          setCreatedDate(response?.data?.data?.result?.created_date);
          setTeamColab(response?.data?.data?.result.team_colab);
          setSuspicious(response?.data?.data?.result.suspicious);
          setComprehensiveAbility(
            response?.data?.data?.result.comprehensive_ability
          );
          setLanguageCommand(response?.data?.data?.result.lang_command);
          setRelevantSkills(response?.data?.data?.result.domain_experties);
          setStability(response?.data?.data?.result.candidate_stability);
          setStart(response?.data?.data?.result.interview_start);
          setEnd(response?.data?.data?.result.interview_end);
          setDescription(response?.data?.data?.result.description);
          console.log(response?.data?.data?.result.candidate_stability);
          setCandidateStability(
            response?.data?.data?.result.candidate_stability
          );
          setFluency(response?.data?.data?.result.fluency);
          setSuspiciousCause(response?.data?.data?.result.suspicious_cause);
        } else if (response?.data.status_code === 400) {
          setLoader(false);
          toast.error("The interview is not completed by candidate. ", {
            toastStyle: {
              borderLeft: "5px solid #f44336",
            },
          });
        } else {
          toast.error(response?.msg, {
            toastStyle: {
              borderLeft: "5px solid #f44336",
            },
          });
        }
        // setLoader(false);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchData(); // Invoke the async function
  }, [overall_score, uuid]);
  console.log(overall_score);
  console.log(candidateStability);
  return (
    <main className="flex flex-col gap-[1.25rem] text-dark-blue p-8 w-screen fill-available-h bg-grey ">
      {isLoader ? <Loader /> : ""}
      <section className="flex items-center fill-available justify-between ">
        <div className="flex">
          <img
            src={arrowIcon}
            alt="arrow icon"
            onClick={() => {
              navigate(-1);
            }}
            className="cursor-pointer"
          />
          <div className="flex flex-col">
            <p>
              {/* {candName}  */}
              Analytics
              {/* for {candCompany} (Job Role: {candJobRole} ) */}
            </p>
            {/* <p>Job Role: {candJobRole}</p> */}
          </div>
        </div>
        {/* <div className="flex gap-4">
          <AnalyticsPageBtn name={"Download"} onClick={()=>{handleDownloadTranscript()}} img={1} />
        </div> */}
      </section>
      <section className="flex flex-col gap-4 justify-between  fill-available">
        <div className="flex justify-between  items-center w-full">
          <p className="font-satoshi flex items-end gap-2">
            <img src={clevercruitlogo} alt="" />
            Ai video interview -{" "}
            <b>Analytics Report - {createdDate.split("T")[0]}</b>
          </p>
          <button className="flex items-center gap-2 px-6 py-3 bg-white rounded-3xl">
            <img className="w-4" src={shareIcon2} alt="" />
            Share
          </button>
        </div>
        <div
          className="px-8 h-[12.7rem] text-white relative flex justify-between items-center overflow-hidden rounded-3xl "
          style={{
            backgroundImage: "linear-gradient(to right, #064197 , #021531 )",
          }}
        >
          <div className="w-[70%]">
            <b>
              <i className="text-[32px]">
                Clevercruit Use our generator to your own, or read on for the
              </i>
            </b>
            <p>
              The passage experienced a surge in popularity during the 1960s
              when Letraset used it on their dry-transfer sheets, and again
              during the. The passage experienced a surge in popularity during
              the 1960s..
            </p>
          </div>
          <div className="h-[70%] border-2 rounded-3xl border-white"></div>
          <div className="flex flex-col gap-2 w-[25%]">
            <b>
              <i>Overall Score</i>
            </b>
            <p>Overall summary of your performance</p>
            <p>
              {" "}
              <b className="text-4xl">
                {overall_score ? parseFloat(overall_score).toFixed(2) : "0.00"}
              </b>{" "}
              Out of 100
            </p>
            <div
              className=" relative w-[22rem] rounded-3xl h-[2rem] border-2 border-white"
              style={{
                backgroundImage:
                  "linear-gradient(to right, #FF0E01, #F6CD02 , #1EF848 )",
              }}
            >
              <div className="border-2 rounded-3xl  border-black">
                <svg
                  width="312"
                  height="25"
                  className="ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="10"
                    y1="14"
                    x2="500"
                    y2="14"
                    stroke="#FFFFFF"
                    stroke-width="15"
                    strokeDasharray="2 , 13"
                  />
                </svg>
              </div>

              <div style={{ position: "absolute", top: "0.6rem", left: marks }}>
                <svg //base 0.275 - 1.225 =0.95
                  width="32"
                  height="21"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polygon
                    points="10,20 30,20 19.8,10"
                    fill="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex gap-4 justify-between fill-available">
        <div className="flex flex-col  gap-3 p-4 ">
          <div className="flex gap-2">
            <div className="relative flex flex-col items-end gap-2 w-[28rem] p-4 rounded-2xl ">
              {/* <Card1 /> */}
              <img
                src={clockSvg}
                className="absolute w-[12.5rem] top-[30%] left-6"
                alt=""
              />

              <b className="bg-light-blue relative text-[78px] text-[#00BD1C] text-center px-1  font-digital rounded-3xl w-[17rem] py-3 ">
                {minutes % 10 === minutes
                  ? `0${minutes} : ${seconds}`
                  : `${minutes} : ${seconds}`}
                <p className="absolute bottom-1 text-[18px] left-12">Mints</p>
                <p className="absolute bottom-1 text-[18px] right-14">Sec</p>
              </b>

              <b className="bg-light-blue text-[#00BD1C] text-[32px] flex flex-col items-end px-4 py-2 rounded-3xl">
                <p>Interview</p>
                <p>Time</p>
              </b>
            </div>

            <div className="bg-white p-6 rounded-3xl border-2">
              <div className="bg-light-grey flex  flex-col gap-3 items-center w-[24rem] h-[22rem] rounded-3xl p-6  ">
                <b>Tech / Domain Skill</b>
                <Card2
                  progres={parseFloat(relevant_skills).toFixed(2)}
                  color={"#00BD1C"}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-8 bg-white rounded-2xl border-[2px] flex-col p-4 ">
            <div>
              <b>
                <i className="text-balck text-[22px]">Communication score</i>
              </b>

              <p>Overall summary of your performance</p>
            </div>

            <div className="flex w-full justify-around ">
              <div className="bg-light-grey flex flex-col gap-3 items-center w-[24rem] h-[22rem] rounded-3xl p-6 ">
                <Card2
                  progres={parseFloat(languageCommand).toFixed(2)}
                  color={"#1AAEFE"}
                />
                <b className="mt-[4.5rem]">Fluency</b>
              </div>

              <div className="bg-light-grey flex  flex-col gap-3 items-center w-[24rem] h-[22rem] rounded-3xl p-6 ">
                <Card2
                  progres={parseFloat(fluency).toFixed(2)}
                  color={"#FEF31A"}
                />
                <b className="mt-[4.5rem]">Grammar</b>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-l from-[#021531] to-[#064197] w-full h-full flex flex-col  gap-2  mt-4 p-4 rounded-xl">
          <div className="flex gap-8 bg-white rounded-2xl items-center border-[2px] flex-col p-4 py-6 ">
            <img
              className="h-[10rem] w-[10rem] border-2 rounded-full"
              src={fullPhotoURL}
              alt="Photo"
            />
            <b className="text-[32px] w-[22rem] text-center text-dark-blue">
              <i>{candName}</i>
            </b>
            <div className="flex justify-around w-full">
              <div className="flex items-center shadow-md border-2 gap-2 px-6 py-3 bg-white rounded-3xl">
                {candJobRole}
              </div>
              <div className="flex items-center shadow-md border-2 gap-2 px-6 py-3 bg-white rounded-3xl">
                {candExp} years
              </div>
            </div>

            <div className="flex gap-8 bg-light-blue rounded-2xl border-[2px] flex-col p-6 ">
              <b className="text-[22px] text-dark-blue">
                <i>About You</i>
              </b>
              <p className="h-[21rem] overflow-scroll">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi
                aut perferendis fugit quibusdam a id exercitationem
                necessitatibus voluptas, ad repellendus aperiam illo earum eos,
                tempore molestiae dicta. At, consectetur soluta? Obcaecati ea
                omnis ratione minus at, suscipit voluptatibus molestiae,
                repellat, deserunt veniam ex odit. Excepturi blanditiis vel
                expedita unde, deleniti vero iste fuga quae veniam. Ratione
                dignissimos reprehenderit vero? Aliquid assumenda esse iste
                recusandae sapiente.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="flex gap-4 justify-between fill-available overflow-auto">
        <div className="bg-white p-8 rounded-3xl border-2 ml-[1rem]">
          <div className="flex  flex-col gap-3 items-center  w-[21rem] h-[22rem] rounded-3xl p-6 ">
            <b>Team Work / Collaboration</b>
            <Card4
              progres={parseFloat(teamColab).toFixed(2)}
              color={"#FE1ADC"}
            />
            {/* <DonutChart
              totalData={{
                progres: parseFloat(teamColab).toFixed(2),
                color: "#FE1ADC",
              }}
            /> */}
          </div>
        </div>
        {/* <div className="flex flex-col items-center bg-white gap-2  w-[28rem] p-4 rounded-2xl border-[2px]">
          <b>Suspicious activity</b>

          <PersonalCard1 percentage={suspicious} />
          <div className="flex items-center">
            <div className="flex flex-col gap-3">
              <div className=" flex gap-3 items-center rounded-3xl p-3 ">
                <div className="bg-[#D57D00] w-[0.7rem] h-[0.6rem] rounded-full"></div>
                <p>Option A</p>
                <p className="flex">Window Manipulate</p>
              </div>
              <div className=" flex gap-3 items-center rounded-3xl p-3 ">
                <div className="bg-[#975CED] w-[0.7rem] h-[0.6rem] rounded-full"></div>
                <p>Option B</p>
                <p>No Face Detected</p>
              </div>
              <div className=" flex gap-3 items-center rounded-3xl p-3 ">
                <div className="bg-[#9C9898] w-[1rem] h-[0.6rem] rounded-full"></div>
                <p>Option C</p>
                <p>Multiple Face / Suspicious</p>
              </div>
            </div>
            <Card3
              total={totalCount}
              prog={totalCount * 20}
              prog2={noFaceDetectedCount * 20 + otherCount * 20}
              prog3={otherCount * 20}
            />
          </div>
          <section className="flex text-white jusitfy-start  items-center  gap-3 text-center">
            <div className="flex bg-[#D57D00] rounded-xl py-3 px-12">
              <p>{dontManipulateWindowCount}</p>
            </div>
            <div className="bg-[#975CED]  rounded-xl py-3 px-12">
              <p>{noFaceDetectedCount}</p>
            </div>
            <div className="bg-[#9C9898] rounded-xl py-3 px-12 ">
              <p>{otherCount}</p>
            </div>
          </section>
        </div> */}

        <div className="flex flex-col items-center bg-white gap-4 w-full max-w-[28rem] p-4 rounded-2xl border-2">
          <b className="text-lg font-bold">Suspicious Activity</b>

          {/* Donut Chart and Legend Section */}
          <div className="flex flex-col md:flex-row items-center md:items-start gap-4 w-full">
            {/* Legend Section */}
            <div className="flex flex-col gap-4 mb-6 md:mb-0 md:w-1/3">
              <div className="flex gap-3 items-center rounded-3xl p-3 ">
                <div className="bg-[#d57d00] w-4 h-4 rounded-full"></div>
                <p className="flex text-[#d57d00]">Window Manipulate</p>
              </div>
              <div className="flex gap-3 items-center rounded-3xl p-3 ">
                <div className="bg-[#975ced] w-4 h-4 rounded-full"></div>
                <p className="text-[#975ced]">No Face Detected</p>
              </div>
              <div className="flex gap-3 items-center rounded-3xl p-3 ">
                <div className="bg-[#9C9898] w-4 h-4 rounded-full"></div>
                <p className="text-[#9c9898]">Multiple Face / Suspicious</p>
              </div>
            </div>

            {/* Donut Chart Section */}
            <div className="w-full md:w-2/3">
              <DonutChart
                totalData={{
                  total: totalCount,
                  noFacePer: noFaceDetectedPercentage,
                  dontManPer: dontManipulateWindowPercentage,
                  multiface: otherPercentage,
                }}
              />
            </div>
          </div>

          {/* Summary Section */}
          <section className="flex flex-col md:flex-row text-white justify-center items-center gap-4 text-center">
            <div className="flex bg-[#D57D00] rounded-xl py-3 px-6 md:px-12">
              <p>{dontManipulateWindowCount}</p>
            </div>
            <div className="bg-[#975CED] rounded-xl py-3 px-6 md:px-12">
              <p>{noFaceDetectedCount}</p>
            </div>
            <div className="bg-[#9C9898] rounded-xl py-3 px-6 md:px-12">
              <p>{otherCount}</p>
            </div>
          </section>
        </div>

        <div className="bg-white p-8 rounded-3xl border-2">
          <div className="flex flex-col gap-3 items-center w-[26rem] h-[22rem] rounded-3xl p-6">
            <b>Stability</b>
            {(() => {
              let parsedStability = candidateStability;

              // Check if candidateStability is a stringified array with single quotes
              if (typeof candidateStability === "string") {
                try {
                  // Replace single quotes with double quotes and parse
                  const sanitizedData = candidateStability
                    .replace(/'/g, '"') // replace single quotes with double quotes
                    .replace(/^\[|\]$/g, ""); // Remove the brackets if it's a string with surrounding brackets

                  parsedStability = JSON.parse(`[${sanitizedData}]`);
                } catch (e) {
                  console.error("Failed to parse candidateStability:", e);
                }
              }

              return Array.isArray(parsedStability) ? (
                parsedStability.map((item, index) => (
                  <p
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span style={{ marginRight: "8px" }}>•</span>{" "}
                    {item.replace(/^"|"$/g, "")}
                  </p>
                ))
              ) : (
                <p>No stability data available</p>
              );
            })()}
          </div>
        </div>
      </section>
      <section className="flex gap-4 justify-between fill-available overflow-auto">
        <div className="flex gap-8 bg-white rounded-2xl border-[2px] w-full flex-col p-4 ">
          <b className="text-[22px]">Your Interview Summary</b>
          <div className="flex gap-8 rounded-2xl bg-light-blue w-full flex-col p-4 ">
            {description}
          </div>
        </div>
      </section>
    </main>
  );
};
